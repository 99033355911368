<template>
  <!-- HORIZONTAL LAYOUT -->
  <vx-card title="Edit Product Team">
    <div class="vx-row mb-6" style="width: 50%">
      <vs-button
        class="ml-4 mt-2"
        color="danger"
        icon-pack="feather"
        icon="icon-arrow-left"
        @click="handleBack()"
        >Back</vs-button
      >
    </div>
    <div class="vx-row">
      <div class="vx-col sm:w-1/2 w-full mb-base">
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/6 w-full">
            <span>Product Team ID</span>
          </div>
          <div class="vx-col sm:w-5/6 w-full">
            <vs-input class="w-full" v-model="this.code" disabled />
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/6 w-full">
            <span>Product Team Description</span>
          </div>
          <div class="vx-col sm:w-5/6 w-full">
            <vs-input class="w-full" v-model="this.name" disabled />
          </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
          <div class="vx-col sm:w-1/3 w-full flex items-center">
            <span>Status</span>
          </div>
          <!-- <div class="vx-col sm:w-2/3 w-full">
            <input type="radio" id="Active" value="Active" v-model="status" />
            <label for="active">Active</label>

            <input
              type="radio"
              id="Inactive"
              value="Inactive"
              v-model="status"
            />
            <label for="inactive">Inactive</label>
          </div> -->
          <div class="vx-col sm:w-2/3 w-full">
            <vs-switch width="120px" color="success" v-model="status">
              <span slot="on">Active</span>
              <span slot="off">Inactive</span>
            </vs-switch>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/6 w-full">
            <span>Line Items</span>
          </div>
          <div class="vx-col sm:w-5/6 w-full">
            <vs-button size="small" class="mr-3 mb-2" @click="browseItem()"
              >Browse</vs-button
            >
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col sm:w-6/12 w-full" v-if="update">
            <vs-button class="mr-3 mb-2" v-on:click="SubmitForm"
              >Save</vs-button
            >
            <!-- <vs-button v-if="quantityChecked != true" class="mr-3 mb-2" v-on:click="CheckQty()">CheckQty</vs-button> -->
            <!-- <vs-button color="danger" class="mr-3 mb-2" @click="CancelForm()"
              >Cancel</vs-button
            > -->
          </div>
        </div>
        <br />
        <hr />
        <br />
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <vs-table
              :data="itemsCode"
              stripe
              style="width: 100%"
              search
              :sst="true"
              :max-items="lengthItem"
              :total="totalItem"
              @change-page="handleChangePageItem"
              @search="handleSearchItem"
              @sort="handleSortItem"
            >
              <template slot="header">
                <vs-dropdown vs-trigger-click class="cursor-pointer">
                  <div
                    class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
                  >
                    <span class="mr-2"
                      >{{ this.startItem }} - {{ this.endItem }} of
                      {{ this.totalItem }}</span
                    >
                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                  </div>
                  <vs-dropdown-menu>
                    <vs-dropdown-item
                      v-for="item in limits"
                      :key="item"
                      @click="handleChangelengthItem(item)"
                    >
                      <span>{{ item }}</span>
                    </vs-dropdown-item>
                  </vs-dropdown-menu>
                </vs-dropdown>
              </template>
              <template slot="thead">
                <vs-th>SKU</vs-th>
                <vs-th>Name</vs-th>
                <vs-th>Categories</vs-th>
                <vs-th>Action</vs-th>
              </template>

              <template slot-scope="{ data }">
                <vs-tr :key="index" v-for="(dt, index) in data">
                  <vs-td style="align-item-center">{{
                    itemsCode[index]
                  }}</vs-td>
                  <vs-td style="align-item-center">{{
                    itemsName[index]
                  }}</vs-td>
                  <vs-td style="align-item-center">{{
                    itemCategories[index]
                  }}</vs-td>
                  <vs-td style="align-item-center">
                    <feather-icon
                      title="Delete"
                      icon="TrashIcon"
                      svgClasses="w-5 h-5 text-danger stroke-current"
                      class="ml-2"
                      @click="deleteItemLine(index)"
                    />
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
            <vs-pagination
              style="padding-top: 5px"
              :total="totalPageItem"
              v-model="setPageItem"
            />
          </div>
        </div>
      </div>
      <div
        v-if="this.optionItemAvailableShow"
        class="vx-col sm:w-1/2 w-full mb-base"
      >
        <div class="vx-row mb-6">
          <h3>Item Unit</h3>
        </div>
        <vs-table
          search
          stripe
          border
          description
          :sst="true"
          :data="items"
          :max-items="length"
          :total="total"
          @search="handleSearch"
          @change-page="handleChangePage"
          @sort="handleSort"
        >
          <template slot="header">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div
                class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
              >
                <span class="mr-2"
                  >{{ this.start }} - {{ this.end }} of {{ this.total }}</span
                >
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item
                  v-for="item in limits"
                  :key="item"
                  @click="handleChangelength(item)"
                >
                  <span>{{ item }}</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </template>
          <template slot="thead">
            <vs-th sort-key="sku">SKU</vs-th>
            <vs-th sort-key="name">Name</vs-th>
            <vs-th sort-key="unit">Supplier</vs-th>
            <vs-th sort-key="category">Categories</vs-th>
            <vs-th sort-key="action">Action</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr
              :data="tr"
              :key="indextr"
              v-for="(tr, indextr) in data.records"
            >
              <vs-td :data="data.records[indextr].sku_code">{{
                data.records[indextr].sku_code
              }}</vs-td>
              <vs-td :data="data.records[indextr].item_name">{{
                data.records[indextr].item_name
              }}</vs-td>
              <vs-td :data="data.records[indextr].supplier_name">{{
                data.records[indextr].supplier_name
              }}</vs-td>
              <vs-td :data="data.records[indextr].categories_name">{{
                data.records[indextr].categories_name
              }}</vs-td>
              <vs-td style="align-item-center">
                <feather-icon
                  title="Add"
                  icon="PlusIcon"
                  svgClasses="w-5 h-5 text-success stroke-current"
                  class="ml-2"
                  @click="addItemLine(tr)"
                />
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <vs-pagination
          style="padding-top: 5px"
          :total="totalPage"
          v-model="setPage"
        />
      </div>
    </div>
  </vx-card>
</template>

<script>
export default {
  components: {},
  mounted() {
    // console.log("mounted - form : ", this.territoryCode, this.territoryId, this.selectedReservation, this.optionTerritory);
    this.update = true;
    this.getTerritory();
    this.ProductItem = this.$route.params.id;
    this.getProductItems(this.ProductItem);
    console.log("hhhhhh", this.ProductItem);
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
    setPageItem: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePageItem(val);
      },
    },
  },
  watch: {
    search: function () {
      if (this.optionItemAvailableShow) {
        this.getOptionItemAvailable();
      }
    },
    itemsQuantity: function () {
      this.quantityChecked = false;
    },
  },
  data: () => ({
    update: false,
    quantityChecked: false,
    external: false,
    itemIdDelete: [],
    itemIdInsert: [],

    itemsCode: [],
    itemsName: [],
    itemsUom: [],
    itemUnitID: [],
    itemCategories: [],

    optionItemAvailable: [{}],

    optionCustomerShow: false,
    optionItemAvailableShow: false,

    limits: [10, 25, 50, 100, "All"],
    limitShow: 10,
    drawData: 0,
    isActive: 1,
    recordsTotal: 0,
    maxPage: 2,
    dataEnd: 0,

    items: [],
    optionTerritory: [],
    selectedTerritory: {},
    start: 1,
    end: 0,
    length: 10,
    page: 1,
    search: "",
    itemSearch: "",
    order: "id",
    sort: "desc",
    total: 0,
    totalPage: 0,
    totalSearch: 0,
    name: "",
    code: "",
    CompanyID: 0,
    ProductItem: [],
    ID: 0,
    status: true,

    startItem: 1,
    endItem: 0,
    lengthItem: 10,
    pageItem: 1,
    searchItem: "",
    itemSearchItem: "",
    orderItem: "id",
    sortItem: "desc",
    totalItem: 0,
    totalPageItem: 0,
    totalSearchItem: 0,
  }),
  methods: {
    handleBack() {
      this.$router.push({
        name: "product-teams",
      });
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    containsKey(obj, key) {
      return Object.keys(obj).includes(key);
    },
    CancelForm() {
      this.update = true;
      this.quantityChecked = false;
      this.external = false;

      this.itemsCode = [];
      this.itemsName = [];
      this.product_teams_id = [];
      this.item_unit_id = [];

      this.itemUnitID = [];
      this.itemCategories = [];

      // this.itemsUom = [];

      this.optionItemAvailable = [{}];

      this.optionItemAvailableShow = false;

      this.$emit("selectReservation", {});
    },
    browseItem() {
      this.search = "";
      this.optionItemAvailableShow = true;
      this.optionCustomerShow = false;
      this.getOptionItemAvailable();
      this.scrollToTop();
    },
    addItemLine(item) {
      // console.log("ini itemLine>>>",);
      this.quantityChecked = false;
      let check = this.itemUnitID.filter((val) => {
        return item.id == val;
      });
      console.log(check);

      if (check.length == 0) {
        this.itemsCode.push(item.sku_code);
        this.itemsName.push(item.item_name);
        // this.itemsUom.push(item.unit_name);
        this.itemUnitID.push(item.id);
        this.itemCategories.push(item.categories_name);

        var idx = this.itemIdDelete.indexOf(item.id);
        if (idx !== -1) {
          this.itemIdDelete.splice(idx, 1);
        } else {
          this.itemIdInsert.push(item.id);
        }
      } else {
        this.$vs.notify({
          title: "Warning",
          text: "You're not allowed to add or remove external Reservation item line ",
          color: "warning",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
      }
    },
    deleteItemLine(index) {
      this.quantityChecked = false;
      if (this.external == false) {
        var idx = this.itemIdInsert.indexOf(this.itemUnitID[index]);
        if (idx !== -1) {
          this.itemIdInsert.splice(idx, 1);
        } else {
          this.itemIdDelete.push(this.itemUnitID[index]);
        }

        this.itemsCode.splice(index, 1);
        this.itemsName.splice(index, 1);
        // this.itemsUom.splice(index, 1);
        this.itemUnitID.splice(index, 1);
        this.itemCategories.splice(index, 1);
      } else {
        this.$vs.notify({
          title: "Warning",
          text: "You're not allowed to add or remove external Reservation item line",
          color: "warning",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
      }
    },
    handleChangelength(val) {
      this.length = val == "All" ? this.total : val;
      this.page = 1;
      this.getOptionItemAvailable();
    },
    getOptionItemAvailable(page = 1) {
      this.isActive = page;
      this.$vs.loading();
      this.$http
        .get("/api/sfa/v1/product-teams/table", {
          params: {
            territory_id: this.selectedTerritory.id,
            length: this.length,
            page: this.page,
            search: this.search,
            order: this.order,
            sort: this.sort,
          },
        })
        .then((resp) => {
          console.log(">>>>>>>>", resp);
          if (resp.code == 200) {
            let company_id = this.$store.state.user.currentUser.company_id;

            this.optionItemAvailable = resp.data.ItemsAvailable;
            this.total = resp.data.total_record;
            this.totalPage = resp.data.total_page;
            this.totalSearch = resp.data.total_record_search;
            this.length = resp.data.total_record_per_page;
            this.items = resp.data;
            this.CompanyID = company_id;
            this.setStartEnd();
            this.$vs.loading.close();
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Item Available",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    SubmitForm() {
      const id = this.$route.params.id;
      console.log("Item Insert : ", this.itemIdInsert);
      console.log("Item Delete : ", this.itemIdDelete);
      // if (this.itemIdInsert.length == 0 || this.itemIdDelete.length == 0) {
      //   this.$vs.notify({
      //     title: "Error",
      //     text: "Please select Item Line first",
      //     color: "danger",
      //     position: "top-right",
      //     iconPack: "feather",
      //     icon: "icon-check",
      //   });
      //   return true;
      // }
      if (this.itemsCode.length == 0) {
        this.$vs.notify({
          title: "Error",
          text: "Please select Item Line first",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return true;
      }

      this.$vs.loading();
      this.$http
        .put("/api/sfa/v1/product-teams/" + id + "/update", {
          code: this.code,
          name: this.name,
          description: this.descriptions,
          Company_id: this.CompanyID,
          item_delete_id: this.itemIdDelete,
          item_add_id: this.itemIdInsert,
          ID: parseInt(this.$route.params.id),
          status: this.status
            ? this.status == true
              ? "Active"
              : "Inactive"
            : "Inactive",
        })
        .then((resp) => {
          // console.log(resp);
          if (resp.code == 200) {
            this.$vs.notify({
              title: "Success",
              text: "Reservation has been added",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
            // this.CancelForm();
          } else {
            this.$vs.notify({
              title: "Error",
              text: resp.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    getTerritory() {
      this.$vs.loading();
      this.$http
        .get("/api/sfa/v1/route-plan/territory", {
          params: {
            length: 0,
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionTerritory = resp.data.records;
              if (this.optionTerritory.length > 0) {
                this.selectedTerritory = this.optionTerritory[0];
              } else {
                this.optionTerritory = [];
                this.selectedTerritory = {};
              }
            } else {
              this.optionTerritory = [];
              this.selectedTerritory = {};
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    customLableTerritory({ code, name }) {
      return `${code} ${name}`;
    },
    setStartEnd() {
      let valStart = this.length * this.page - this.length + 1;
      if (valStart > this.total) {
        valStart = 1;
      }
      if (this.total == 0) {
        valStart = 0;
      }
      let valEnd = this.length * this.page;
      if (valEnd > this.total) {
        valEnd = this.total;
      }
      if (this.totalSearch < this.total) {
        valEnd = this.totalSearch;
      }

      this.start = valStart;
      this.end = valEnd;
    },
    handleSearch(searching) {
      this.search = searching;
      this.page = 1;
      this.getOptionItemAvailable(this.isActive);
    },
    handleChangePage(page) {
      this.page = page;
      this.getOptionItemAvailable(this.isActive);
    },
    handleSort(key, active) {
      this.order = key;
      this.sort = active;
      this.getOptionItemAvailable(this.isActive);
    },
    getProductItems(id) {
      this.$http
        .get("/api/sfa/v1/product-teams/" + id + "/edit", {
          params: {
            page: this.pageItem,
            search: this.itemSearchItem,
            order: this.orderItem,
            sort: this.sortItem,
            length: this.lengthItem,
          },
        })
        .then((resp) => {
          console.log(resp);
          if (resp.code == 200) {
            console.log(">>>>>>>", resp.data);
            this.items = resp.data.records;
            this.itemsCode = [];
            this.itemsName = [];
            this.itemsUom = [];
            this.itemUnitID = [];

            this.totalItem = resp.data.total_record;
            this.totalPageItem = resp.data.total_page;
            this.totalSearchItem = resp.data.total_record_search;
            this.lengthItem = resp.data.total_record_per_page;

            this.code = resp.data.data_product_team.Code;
            this.name = resp.data.data_product_team.Name;
            this.descriptions = resp.data.data_product_team.Description;
            this.status = resp.data.data_product_team.Status
              ? resp.data.data_product_team.Status == "Active"
                ? true
                : false
              : false;
            for (let index = 0; index < resp.data.Data_Item.length; index++) {
              this.itemCategories.push(
                resp.data.Data_Item[index].categories_name
              );
              this.itemsCode.push(resp.data.Data_Item[index].sku_code);
              this.itemsName.push(resp.data.Data_Item[index].name);
              // this.itemsUom.push(resp.data.Data_Item[index].unit.Name);
              this.itemUnitID.push(resp.data.Data_Item[index].id);
            }
            this.setStartEndItem();
          }
        });
    },
    //paggination for item show
    handleChangePageItem(page) {
      this.pageItem = page;
      this.getProductItems(this.$route.params.id);
    },
    handleSearchItem(searching) {
      this.itemSearchItem = searching;
      this.pageItem = 0;
      this.getProductItems(this.$route.params.id);
    },
    handleSortItem(key, active) {
      this.orderItem = key;
      this.sortItem = active;
      this.getProductItems(this.$route.params.id);
    },
    handleChangelengthItem(val) {
      this.lengthItem = val == "All" ? this.totalItem : val;
      this.pageItem = 1;
      this.getProductItems(this.$route.params.id);
    },
    setStartEndItem() {
      let valStart = this.lengthItem * this.pageItem - this.lengthItem + 1;

      if (valStart > this.totalItem) {
        valStart = 1;
      }
      if (this.totalItem == 0) {
        valStart = 0;
      }
      let valEnd = this.lengthItem * this.pageItem;

      if (valEnd > this.totalItem) {
        valEnd = this.totalItem;
      }

      if (this.totalSearchItem < this.totalItem && this.searchItem != "") {
        valEnd = this.totalSearchItem;
      }

      this.startItem = valStart;
      this.endItem = valEnd;
    },
  },
};
</script>
